<template>
<div>
    <sidebar-view />
    <div
      class="wrapper d-flex flex-column min-vh-100 bg-light pt-3"
      style="background: ##dbdbdb !important"
    >
    <router-view/>
    </div>
</div>
</template>

<script>
import SidebarView from './SidebarView.vue'
export default {
    components: {
        SidebarView
    }
}
</script>

<style>

</style>