import axios from 'axios';

// const token = localStorage.getItem('token')
const base_url=process.env.VUE_APP_API_URL

const client = axios.create({
  baseURL: base_url,
  headers: {
    "Content-Type": "application/json"
    // Authorization: `Bearer ${token}`
  }
})

client.interceptors.request.use(config => {
  let token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
    }

    return config
})

client.interceptors.response.use(response => {
  return response;
}, error =>{
  if(error.response.status == 401){
    console.log('Unauthorized')
    localStorage.removeItem('token')
    window.location.replace("/");
  }
  return Promise.reject(error);
})

export default client