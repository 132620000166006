<template>
    <CContainer class="h-100 d-flex">
        <CRow class="my-auto w-100 mx-5">
            <CCol :sm="{ span: '12' }" :md="{ span: '8' }" :lg="{ span: '6', offset: '3' }" class="bg-white offset-md- 2 offset-lg-3 text-start  p-5 shadow">
                <h1>Login</h1>
                <hr>
                <CFormInput
                    type="text"
                    id="txtUsername"
                    label="Username"
                    placeholder="Enter your username"
                    text="Please enter your username properly"
                    @input="username = $event.target.value"
                    class="py-3"
                />
                <br>
                <CFormInput
                    type="password"
                    id="txtPass"
                    label="Password"
                    placeholder="Enter your password"
                    text="Please enter your password properly"
                    @input="password = $event.target.value"
                    class="py-3"
                />
                <br v-if="submitting">
                <loading-view   v-if="submitting" />
                <br v-if="error != ''">
                <CAlert color="danger" v-if="error != ''">{{error}}</CAlert>
                <br>
                <CButton color="primary" size="lg" class="w-100" @click="login">LOGIN</CButton>

            </CCol>
        </CRow>
    </CContainer>
</template>

<script>
import axios from 'axios';
import LoadingView from '../../components/LoadingView.vue'
export default {
    setup() {
        
    },
    components:{
        LoadingView
    },
    data(){
        return {
            submitting: false,
            username:'',
            password:'',
            error: ''
        }
    },
    created(){
        document.body.style.backgroundColor = "#d9d9d9";
    },
    methods:{
        login(){
            if(this.username != '' && this.username != ''){
                this.submitting=true
                this.error = ''
                
                axios.post(`${process.env.VUE_APP_API_URL}/admin/auth/login`, {username: this.username, password: this.password})
                .then(response => {
                    
                    if(response.data && response.data.success == true){
                        localStorage.setItem("token", response.data.data.jwt)
                        this.$router.push({name: 'base'})
                    }else{
                        this.error = 'Please check your login information again.'
                    }
                    this.submitting=false
                })
                .catch(err => {
                    console.log(err)
                    this.submitting=false
                    this.error = 'Please check your login information again.'
                })

            }else{
                this.error = 'All field is required'
            }
        }
    }
}
</script>
