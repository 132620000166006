<template>
    <h1 class="text-start mx-4"><strong>Add new movie</strong></h1>
  <div class="home bg-white p-3 mx-4 mt-3 shadow text-start">
    <CForm>
      <CFormInput
        type="text"
        id="txtDownloadLink"
        label="Download link"
        placeholder="enter download link"
        text="max char is 255."
        @input="newMovie.download_link = $event.target.value"
        aria-describedby="txtDownloadLink"
      />
      <CFormInput
        type="text"
        id="txtImdbId"
        label="IMDB Id"
        placeholder="enter movie imdb id"
        text="max char is 255."
        @input="newMovie.imdb_id = $event.target.value"
        aria-describedby="txtImdbId"
      />
      <div class="w-100 py-3 px-4" v-if="message != ''">Status: {{ message }}</div>
      
      <CButton v-if="!movieFound" color="primary" class="mt-5 w-100" type="button" @click="checkTmdb">Check</CButton>
      <br>
      <br>
      <div class="w-100 d-block" v-if="movieFound">

        <div class="w-100 py-3"><strong>Movie name:</strong><br></div>
        <div class="w-100 py-3"> {{newMovie.movie_name}}<br> <hr></div>

        <div class="w-100 py-3"><strong>Movie year:</strong><br></div>
        <div class="w-100 py-3"> {{this.moment(newMovie.movie_year).year()}}<br> <hr></div>
        
        <div class="w-100 py-3"><strong>Description/overview:</strong><br></div>
        <div class="w-100 py-3"> {{newMovie.description}}<br> <hr></div>

        <div class="w-100 py-3"><strong>Genre:</strong><br></div>
        <div class="w-100 py-3">
          <CListGroup>
            <CListGroupItem v-for="gen in newMovie.genre" v-bind:key="gen.id">{{gen.name}}</CListGroupItem>
          </CListGroup>
          
        </div>
        <div class="w-100 py-3"><strong>Cast:</strong><br></div>
        <div class="w-100 py-3">
          <CListGroup>
            <CListGroupItem v-for="person in newMovie.cast" v-bind:key="person.id">{{person.name}}</CListGroupItem>
          </CListGroup>
          
        </div>

        <!-- <CFormInput
          type="text"
          id="txtMovieTitle"
          label="Movie name"
          placeholder="Movie name here"
          :value="newMovie.movie_name"
          aria-describedby="txtMovieTitle"
          disabled
          class="mt-2"
        />
        <br>
        <CFormInput
          type="text"
          id="txtMovieDesc"
          label="Overview"
          placeholder="Movie desc here"
          :value="newMovie.description"
          aria-describedby="txtMovieDesc"
          class="mt-2"
          disabled
        /> -->
        <br>
        <strong>Poster image:</strong>
        <br>
        <CImage rounded thumbnail :src="newMovie.poster_image" width="350" height="auto"/>
        <hr>
        <br>
        <strong>Backdrop image:</strong>
        <br>
        <CImage rounded thumbnail :src="newMovie.backdrop_image" width="450" height="auto"/>
        <br>
        <br>
        <loading-view v-if="submitState" />
        <CAlert color="success" v-if="submitSuccess">
          Movie saved, redirecting in 3 second to movie list
        </CAlert>
        <CAlert color="danger" v-if="submitError">
          {{submitErrorMessage}}
        </CAlert>
        <CButton v-if="movieFound" color="primary" class="mt-3 w-100" type="button" @click="submitData">Submit</CButton>
        <CButton v-if="movieFound" color="danger" class="mt-3 w-100" type="button" @click="movieFound = false">cancel</CButton>
        <br>
        <br>
        <br>
      </div>

    </CForm>
  </div>
</template>

<script>
// @ is an alias to /src
// import { storeToRefs } from 'pinia'
import {reactive} from 'vue'
import axios from 'axios'
import client from '@/helpers/axios'

import LoadingView from '@/components/LoadingView.vue'


export default {
  name: 'MovieView',
  components: {
    LoadingView
  },
  setup(){
    
    var newMovie = reactive({
      movie_name:"",
      imdb_id: "",
      description:"",
      poster_image:"",
      backdrop_image:"",
      download_link:"",
      cast:[],
      genre:[]
    })


    return {
      newMovie
    }
  },
  data(){
    return {
      message: '',
      movieFound: false,
      submitMessage: '',
      submitState: false,
      submitSuccess:false,
      submitError:false,
      submitErrorMessage:''
    }
  },
  created(){
    
  },
  methods: {

    //29e1482456437a46f0c1b6dedf3d84dc
    checkTmdb(){
      console.log(`Checkcing movie in tmdb, imdb id: ${this.newMovie.imdb_id}`)

      this.message = "Searching movie data"
      this.movieFound = false

      axios.get(`https://api.themoviedb.org/3/find/${this.newMovie.imdb_id}?external_source=imdb_id&api_key=29e1482456437a46f0c1b6dedf3d84dc`)
      .then(response => {
          // this.movies = response.data.data
          // this.pagination = response.data.pagination
          // this.calculatePage()
          if(response.data.movie_results.length > 0){
            this.message = "Movie found!"
            this.movieFound = true
            this.newMovie.movie_name = response.data.movie_results[0].title
            this.newMovie.description = response.data.movie_results[0].overview
            this.newMovie.movie_year = response.data.movie_results[0].release_date
            this.newMovie.poster_image = `https://image.tmdb.org/t/p/w185${response.data.movie_results[0].poster_path}`
            this.newMovie.backdrop_image = `https://image.tmdb.org/t/p/w500${response.data.movie_results[0].backdrop_path}`
            this.message = "Retrieving movie metadata..."
             axios.get(`https://api.themoviedb.org/3/movie/${response.data.movie_results[0].id}?api_key=29e1482456437a46f0c1b6dedf3d84dc&append_to_response=credits`)
             .then(metaRes => {
                this.message = "Metadata found"
               this.newMovie.cast = metaRes.data.credits.cast
               this.newMovie.genre = metaRes.data.genres
               setTimeout(()=>{this.message = ""}, 3000)
             })
             .catch(err => {
                console.log(err)
                this.movieFound = false
              })
          }else{
            this.message = "Movie not found!"
            this.movieFound = false
          }
          // console.log(response.data)
      })
      .catch(err => {
          console.log(err)
          this.movieFound = false
      })
    },
    submitData(){
      this.submitState = true
      this.submitErrorMessage = ''
      this.submitSuccess=false
      this.submitError=false
      this.message="Saving new movie to system"
      client.post('/admin/movie',this.newMovie)
      .then(response => {
          if(response.data.data != null){
            this.message="Movie saved, redirecting in 3 second to movie list"
            this.submitState = false
            this.submitSuccess=true
            setTimeout(()=>{
              this.$router.push({name: 'movie-list'})
            }, 3000)
          }else{
            this.submitErrorMessage = response.data.error
            this.message="Submission failed!"
            this.submitState = false
            this.submitError=true
          }
          // console.log(response.data)
      })
      .catch(err => {
          console.log(err)
          this.submitErrorMessage = 'Something odds happened, submission failed.'
          this.message="Submission failed!"
          this.submitState = false
          this.submitError=true
      })

    }
    
  }
}
</script>
