import { defineStore } from "pinia";
import client from './../helpers/axios'

export const useActorStore = defineStore("actor", {
    state: ()=> {
        return { 
            actors: [],
            pagination: {
                totalPage: 0,
                nextPage: false,
                prevPage: false,
                currentPage: 1
            },
            pages: []
         }
    },
    actions: {
        fetchData(page = 1){
            client.get(`/admin/actor?page=${page}`)
            .then(response => {
                this.actors = response.data.data
                this.pagination = response.data.pagination
                this.calculatePage()
                console.log(response.data)
            })
            .catch(err => {
                console.log(err)
            })
        },
        calculatePage(){
            this.pages = []

            var min = 0
            var max = 0

            if(this.pagination.currentPage != 1 && (this.pagination.currentPage % 10) == 0){
                max = this.pagination.currentPage
                min = max - 9
            }else if(this.pagination.currentPage == 1){
                min = 1
                max = 10
            }else{
                min = Math.floor(this.pagination.currentPage / 10) * 10;
                max = min + 10;
                min = min + 1;
            }

            // var max = Math.ceil(this.pagination.currentPage / 10) * 10;


            for(var x = (min); x <= max; x++){
                if(this.pagination.totalPage >= x){
                    this.pages.push(x);
                }else{
                    break;
                }
            }

            console.log(this.pages)
        }
    },getters: {
        getMovies: (state) => state.movies
    }
})