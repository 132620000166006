<template>
  <div class="row">
    <div class="col-6">
      <h1 class="text-start mx-4"><strong>Genre list</strong></h1>

    </div>
    <div class="col-6 my-auto text-end px-5">
      <!-- <CButton color="primary" class="" type="button" @click="$router.push({name: 'movie-create'})">Create</CButton> -->

    </div>
  </div>
  <div class="home bg-white p-3 mx-4 mt-3 shadow text-start">
    <CTable>
  <CTableHead>
    <CTableRow>
      <CTableHeaderCell scope="col">Genre</CTableHeaderCell>
    </CTableRow>
  </CTableHead>
  <CTableBody>
    <CTableRow v-for="genre in genreStore.genres" v-bind:key="genre.id">
      <CTableDataCell>{{ genre.category_name }}</CTableDataCell>
      <!-- <CTableDataCell><CImage rounded thumbnail :src="movie.poster_image" width="100" height="auto"/></CTableDataCell> -->
      <!-- <CTableDataCell>
        <CImage rounded thumbnail :src="movie.backdrop_image" width="250" height="auto"/>
      </CTableDataCell> -->
      <!-- <CTableDataCell>{{ movie.download_link }}</CTableDataCell> -->
      <!-- <CTableDataCell>
        <CButton color="dark" class="mx-1">edit</CButton>
      </CTableDataCell> -->
    </CTableRow>
    
  </CTableBody>
</CTable>

<CPagination align="center" aria-label="Page navigation example">
  <CPaginationItem :disabled="!genreStore.pagination.prevPage" href="#" @click="genreStore.fetchData(parseInt(genreStore.pagination.currentPage) - 1)">Previous</CPaginationItem>
  <CPaginationItem v-for="x in genreStore.pages" v-bind:key="x" :disabled="parseInt(genreStore.pagination.currentPage) == x"  href="#" @click="genreStore.fetchData(x)">{{ x }}</CPaginationItem>
  <!-- <CPaginationItem href="#">2</CPaginationItem>
  <CPaginationItem href="#">3</CPaginationItem> -->
  <CPaginationItem :disabled="!genreStore.pagination.nextPage" href="#" @click="genreStore.fetchData(parseInt(genreStore.pagination.currentPage) + 1)">Next</CPaginationItem>
</CPagination>
  </div>
</template>

<script>
// @ is an alias to /src
// import { storeToRefs } from 'pinia'
import {useGenreStore} from '../../stores/genre'



export default {
  name: 'GenreView',
  components: {
    },
  setup(){
    const genreStore = useGenreStore();
    const pages = [];
    // const { genres } = storeToRefs(genreStore);
    


    return {
      genreStore,
      pages
    }
  },
  mounted(){
    console.log('actor mounted')
    this.genreStore.fetchData()
  },
  methods: {
    getPages(){
      
    }
  }
}
</script>
