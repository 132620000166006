import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import CoreuiVue from '@coreui/vue'
import moment from 'moment' 

import './styles/style.scss'

const app = createApp(App).use(router)

app.config.globalProperties.moment = moment

app.use(createPinia())
app.use(CoreuiVue)

app.mount('#app')
