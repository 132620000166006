<template>
  <div class="row">
    <div class="col-6">
      <h1 class="text-start mx-4"><strong>Movie list</strong></h1>

    </div>
    <div class="col-6 my-auto text-end px-5">
      <CButton color="primary" class="" type="button" @click="$router.push({name: 'movie-create'})">Create</CButton>

    </div>
  </div>
  <div class="home bg-white p-3 mx-4 mt-3 shadow text-start">
    <CTable>
  <CTableHead>
    <CTableRow>
      <CTableHeaderCell scope="col">Title</CTableHeaderCell>
      <CTableHeaderCell scope="col">Poster</CTableHeaderCell>
      <CTableHeaderCell scope="col">Backdrop</CTableHeaderCell>
      <CTableHeaderCell scope="col">Download link</CTableHeaderCell>
      <CTableHeaderCell scope="col">Action</CTableHeaderCell>
      <!-- <CTableHeaderCell scope="col">Action</CTableHeaderCell> -->
    </CTableRow>
  </CTableHead>
  <CTableBody>
    <CTableRow v-for="movie in movieStore.movies" v-bind:key="movie.imdb_id">
      <CTableDataCell>{{ movie.movie_name }}</CTableDataCell>
      <CTableDataCell><CImage rounded thumbnail :src="movie.poster_image" width="100" height="auto"/></CTableDataCell>
      <CTableDataCell>
        <CImage rounded thumbnail :src="movie.backdrop_image" width="250" height="auto"/>
      </CTableDataCell>
      <CTableDataCell>{{ movie.download_link }}</CTableDataCell>
      <CTableDataCell>
        <CButton color="warning" @click="$router.push({name: 'movie-edit', query: {movieId: movie.id}})">Edit</CButton>
      </CTableDataCell>
      <!-- <CTableDataCell>
        <CButton color="dark" class="mx-1">edit</CButton>
      </CTableDataCell> -->
    </CTableRow>
    
  </CTableBody>
</CTable>

<CPagination align="center" aria-label="Page navigation example">
  <CPaginationItem :disabled="!movieStore.pagination.prevPage" href="#" @click="movieStore.fetchData(parseInt(movieStore.pagination.currentPage) - 1)">Previous</CPaginationItem>
  <CPaginationItem v-for="x in movieStore.pages" v-bind:key="x" :disabled="movieStore.pagination.currentPage == x"  href="#" @click="movieStore.fetchData(x)">{{ x }}</CPaginationItem>
  <!-- <CPaginationItem href="#">2</CPaginationItem>
  <CPaginationItem href="#">3</CPaginationItem> -->
  <CPaginationItem :disabled="!movieStore.pagination.nextPage" href="#" @click="movieStore.fetchData(parseInt(movieStore.pagination.currentPage) + 1)">Next</CPaginationItem>
</CPagination>
  </div>
</template>

<script>
// @ is an alias to /src
// import { storeToRefs } from 'pinia'
import {useMovieStore} from '../../stores/movie'



export default {
  name: 'MovieView',
  components: {
    },
  setup(){
    const movieStore = useMovieStore();
    const pages = [];
    // const { movies } = storeToRefs(movieStore);
    


    return {
      movieStore,
      pages
    }
  },
  mounted(){
    console.log('movie mounted')
    this.movieStore.fetchData()
  },
  methods: {
    getPages(){
      
    }
  }
}
</script>
