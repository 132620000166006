<template>
    <h1 class="text-start mx-4"><strong>Edit movie</strong></h1>
  <div class="home bg-white p-3 mx-4 mt-3 shadow text-start">
    <CForm v-if="!movieStore.waiting">
      <CFormInput
        type="text"
        id="txtDownloadLink"
        label="Download link"
        placeholder="enter download link"
        text="max char is 255."
        v-model="existingMovie.download_link"
        aria-describedby="txtDownloadLink"
      />
      <CFormInput
        type="text"
        id="txtImdbId"
        label="IMDB Id"
        placeholder="enter movie imdb id"
        text="max char is 255."
        v-model="existingMovie.imdb_id"
        aria-describedby="txtImdbId"
      />
      <div class="w-100 py-3 px-4" v-if="message != ''">Status: {{ message }}</div>

      <loading-view v-if="!movieFound" />
      
      <!-- <CButton v-if="!movieFound" color="primary" class="mt-5 w-100" type="button" @click="checkTmdb">Check</CButton> -->
      <CButton v-if="movieFound" color="light" class="mt-5" type="button" @click="checkTmdb">Revalidate TMDB</CButton>
      <br>
      <br>
      <div class="w-100 d-block" v-if="movieFound">

        <div class="w-100 py-3"><strong>Movie name:</strong><br></div>
        <div class="w-100 py-3"> {{existingMovie.movie_name}}<br> <hr></div>

        <div class="w-100 py-3"><strong>Movie year:</strong><br></div>
        <div class="w-100 py-3"> {{this.moment(existingMovie.movie_year).year()}}<br> <hr></div>
        
        <div class="w-100 py-3"><strong>Description/overview:</strong><br></div>
        <div class="w-100 py-3"> {{existingMovie.description}}<br> <hr></div>

        <div class="w-100 py-3"><strong>Genre:</strong><br></div>
        <div class="w-100 py-3">
          <CListGroup>
            <CListGroupItem v-for="gen in existingMovie.genre" v-bind:key="gen.id">{{gen.name}}</CListGroupItem>
          </CListGroup>
          
        </div>
        <div class="w-100 py-3"><strong>Cast:</strong><br></div>
        <div class="w-100 py-3">
          <CListGroup>
            <CListGroupItem v-for="person in existingMovie.cast" v-bind:key="person.id">{{person.name}}</CListGroupItem>
          </CListGroup>
          
        </div>

        <!-- <CFormInput
          type="text"
          id="txtMovieTitle"
          label="Movie name"
          placeholder="Movie name here"
          :value="existingMovie.movie_name"
          aria-describedby="txtMovieTitle"
          disabled
          class="mt-2"
        />
        <br>
        <CFormInput
          type="text"
          id="txtMovieDesc"
          label="Overview"
          placeholder="Movie desc here"
          :value="existingMovie.description"
          aria-describedby="txtMovieDesc"
          class="mt-2"
          disabled
        /> -->
        <br>
        <strong>Poster image:</strong>
        <br>
        <CImage rounded thumbnail :src="existingMovie.poster_image" width="350" height="auto"/>
        <hr>
        <br>
        <strong>Backdrop image:</strong>
        <br>
        <CImage rounded thumbnail :src="existingMovie.backdrop_image" width="450" height="auto"/>
        <br>
        <br>
        <loading-view v-if="submitState" />
        <CAlert color="success" v-if="submitSuccess">
          Movie saved, redirecting in 3 second to movie list
        </CAlert>
        <CAlert color="danger" v-if="submitError">
          {{submitErrorMessage}}
        </CAlert>
        <CButton v-if="movieFound" color="primary" class="mt-3 w-100" type="button" @click="submitData">Submit</CButton>
        <CButton v-if="movieFound" color="danger" class="mt-3 w-100" type="button" @click="movieFound = false">cancel</CButton>
        <br>
        <br>
        <br>
      </div>

    </CForm>
  </div>
</template>

<script>
// @ is an alias to /src
// import { storeToRefs } from 'pinia'
import {ref} from 'vue'
import axios from 'axios'
import client from '@/helpers/axios'
import {useRoute, useRouter} from 'vue-router'

import LoadingView from '@/components/LoadingView.vue'
import {useMovieStore} from '@/stores/movie'


export default {
  name: 'MovieView',
  components: {
    LoadingView
  },
  setup(){
    const route = useRoute()
    const router = useRouter()

    const movieId = route.query.movieId
    const movieStore = useMovieStore()

    movieStore.fetchById(movieId)
    
    var existingMovie = ref({
      movie_name:"",
      imdb_id: "",
      description:"",
      poster_image:"",
      backdrop_image:"",
      download_link:"",
      cast:[],
      genre:[]
    })

    movieStore.$subscribe((_ , state) => {
      if(!state.waiting && state.movie != null) {
        existingMovie.value.download_link = state.movie.download_link
        existingMovie.value.imdb_id = state.movie.imdb_id
        checkTmdb()
      }
    })

    const message = ref('')
    const movieFound = ref(false)
    const submitMessage = ref('')
    const submitState = ref( false)
    const submitSuccess = ref(false)
    const submitError = ref(false)
    const submitErrorMessage = ref('')

    const revalidateResult = () => {
      checkTmdb()
    }

    const checkTmdb = () => {
      console.log(`Checkcing movie in tmdb, imdb id: ${existingMovie.value.imdb_id}`)

      message.value = "Searching movie data"
      movieFound.value = false

      axios.get(`https://api.themoviedb.org/3/find/${existingMovie.value.imdb_id}?external_source=imdb_id&api_key=29e1482456437a46f0c1b6dedf3d84dc`)
      .then(response => {
          // this.movies = response.data.data
          // this.pagination = response.data.pagination
          // this.calculatePage()
          if(response.data.movie_results.length > 0){
            message.value = "Movie found!"
            movieFound.value = true
            existingMovie.value.movie_name = response.data.movie_results[0].title
            existingMovie.value.description = response.data.movie_results[0].overview
            existingMovie.value.movie_year = response.data.movie_results[0].release_date
            existingMovie.value.poster_image = `https://image.tmdb.org/t/p/w185${response.data.movie_results[0].poster_path}`
            existingMovie.value.backdrop_image = `https://image.tmdb.org/t/p/w500${response.data.movie_results[0].backdrop_path}`
            message.value = "Retrieving movie metadata..."
             axios.get(`https://api.themoviedb.org/3/movie/${response.data.movie_results[0].id}?api_key=29e1482456437a46f0c1b6dedf3d84dc&append_to_response=credits`)
             .then(metaRes => {
                message.value = "Metadata found"
               existingMovie.value.cast = metaRes.data.credits.cast
               existingMovie.value.genre = metaRes.data.genres
               setTimeout(()=>{message.value = ""}, 3000)
             })
             .catch(err => {
                console.log(err)
                movieFound.value = false
              })
          }else{
            message.value = "Movie not found!"
            movieFound.value = false
          }
          // console.log(response.data)
      })
      .catch(err => {
          console.log(err)
          movieFound.value = false
      })
    }

    const submitData = () => {
      submitState.value = true
      submitErrorMessage.value = ''
      submitSuccess.value=false
      submitError.value=false
      message.value="Saving movie to system"
      client.put('/admin/movie/'+movieId,existingMovie.value)
      .then(response => {
        console.log('oke')
          if(response.data.data != null){
            message.value="Movie saved, redirecting in 3 second to movie list"
            submitState.value = false
            submitSuccess.value=true
            setTimeout(()=>{
              router.push({name: 'movie-list'})
            }, 3000)
          }else{
            submitErrorMessage.value = response.data.error
            message.value="Submission failed!"
            submitState.value = false
            submitError.value=true
          }
          // console.log(response.data)
      })
      .catch(err => {
          console.log(err)
          submitErrorMessage.value = 'Something odds happened, submission failed.'
          message.value="Submission failed!"
          submitState.value = false
          submitError.value=true
      })

    }


    return {
      existingMovie,
      submitMessage,
      submitState,
      submitSuccess,
      submitError,
      submitErrorMessage,
      movieStore,
      movieId,
      router,
      route,
      message,
      movieFound,
      checkTmdb,
      submitData,
      revalidateResult,
    }
  },
}
</script>
