<template>
  <div class="row">
    <div class="col-6">
      <h1 class="text-start mx-4"><strong>Actor list</strong></h1>

    </div>
    <div class="col-6 my-auto text-end px-5">
      <!-- <CButton color="primary" class="" type="button" @click="$router.push({name: 'movie-create'})">Create</CButton> -->

    </div>
  </div>
  <div class="home bg-white p-3 mx-4 mt-3 shadow text-start">
    <CTable>
  <CTableHead>
    <CTableRow>
      <CTableHeaderCell scope="col">Actor</CTableHeaderCell>
    </CTableRow>
  </CTableHead>
  <CTableBody>
    <CTableRow v-for="movie in actorStore.actors" v-bind:key="movie.imdb_id">
      <CTableDataCell>{{ movie.actor_name }}</CTableDataCell>
      <!-- <CTableDataCell><CImage rounded thumbnail :src="movie.poster_image" width="100" height="auto"/></CTableDataCell> -->
      <!-- <CTableDataCell>
        <CImage rounded thumbnail :src="movie.backdrop_image" width="250" height="auto"/>
      </CTableDataCell> -->
      <!-- <CTableDataCell>{{ movie.download_link }}</CTableDataCell> -->
      <!-- <CTableDataCell>
        <CButton color="dark" class="mx-1">edit</CButton>
      </CTableDataCell> -->
    </CTableRow>
    
  </CTableBody>
</CTable>

<CPagination align="center" aria-label="Page navigation example">
  <CPaginationItem :disabled="!actorStore.pagination.prevPage" href="#" @click="actorStore.fetchData(parseInt(actorStore.pagination.currentPage) - 1)">Previous</CPaginationItem>
  <CPaginationItem v-for="x in actorStore.pages" v-bind:key="x" :disabled="parseInt(actorStore.pagination.currentPage) == x"  href="#" @click="actorStore.fetchData(x)">{{ x }}</CPaginationItem>
  <!-- <CPaginationItem href="#">2</CPaginationItem>
  <CPaginationItem href="#">3</CPaginationItem> -->
  <CPaginationItem :disabled="!actorStore.pagination.nextPage" href="#" @click="actorStore.fetchData(parseInt(actorStore.pagination.currentPage) + 1)">Next</CPaginationItem>
</CPagination>
  </div>
</template>

<script>
// @ is an alias to /src
// import { storeToRefs } from 'pinia'
import {useActorStore} from '../../stores/actor'



export default {
  name: 'ActorView',
  components: {
    },
  setup(){
    const actorStore = useActorStore();
    const pages = [];
    // const { actors } = storeToRefs(actorStore);
    


    return {
      actorStore,
      pages
    }
  },
  mounted(){
    console.log('actor mounted')
    this.actorStore.fetchData()
  },
  methods: {
    getPages(){
      
    }
  }
}
</script>
