import { createRouter, createWebHistory } from 'vue-router'
import DashboardLayout from '../views/baselayout/DashboardLayout.vue'
import loginView from '../views/auth/loginView.vue'
import GenreListView from '../views/genre/GenreListView.vue'
import ActorListView from '../views/actor/ActorListView.vue'
import MovieListView from '../views/movie/MovieListView.vue'
import MovieCreateView from '../views/movie/MovieCreateView.vue'
import MovieEditView from '../views/movie/MovieEditView.vue'

const routes = [
  {
    path: '/',
    name: 'base',
    redirect: to => {
      console.log(to)
      return { name: 'movie-list' }
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    meta: {
      requiresAuth: false,
    },
    component: loginView,
  },
  {
    path: '/admin',
    name: 'admin-base',
    meta: {
      requiresAuth: true,
    },
    component: DashboardLayout,
    children:[
      {
        path: 'movie',
        name: 'movie-list',
        component: MovieListView,
      },
      {
        path: 'movie/create',
        name: 'movie-create',
        component: MovieCreateView,
      },
      {
        path: 'movie/edit',
        name: 'movie-edit',
        component: MovieEditView,
      },
      {
        path: 'actor',
        name: 'actor-list',
        component: ActorListView,
      },
      {
        path: 'genre',
        name: 'genre-list',
        component: GenreListView,
      }
      // {
      //   path: 'actor/create',
      //   name: 'actor-create',
      //   component: ActorCreateView,
      // }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  console.log(to)
  if(to.name != "auth-login" && to.meta.requiresAuth){
    var token = localStorage.getItem('token')
    if(token == null){
      return { name: 'auth-login' }
    }
  }else{
    localStorage.removeItem('token')
  }
})

export default router
