<template>
    <CSidebar visible class="h-100 main-sidebar" position="fixed">
    <CSidebarBrand><b>DASHBOARD</b></CSidebarBrand>
    <CSidebarNav>
      <!-- <li class="nav-title">Nav Title</li> -->
      <CNavItem>
        <CNavLink
        href="javascript:void(0);"
         @click="$router.push({name: 'movie-list'})"
      >
        <CIcon  customClassName="nav-icon" icon="cil-speedometer"/>
        Movie
      </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink
          href="javascript:void(0);"
          @click="$router.push({name: 'actor-list'})"
        >
        <CIcon  customClassName="nav-icon" icon="cil-speedometer"/>
       Actor
      </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink
          href="javascript:void(0);"
          @click="$router.push({name: 'genre-list'})"
        >
        <CIcon  customClassName="nav-icon" icon="cil-speedometer"/>
       Genre
      </CNavLink>
      </CNavItem>
      <!-- <CNavItem href="#">
          <CIcon  customClassName="nav-icon" icon="cil-speedometer"/>
          With badge
          <CBadge color="primary ms-auto">NEW</CBadge>
      </CNavItem>
      <CNavGroup>
        <template #togglerContent>
          <CIcon  customClassName="nav-icon" icon="cil-puzzle"/> Nav dropdown
        </template>
        <CNavItem href="#">
          <CIcon  customClassName="nav-icon" icon="cil-puzzle"/> Nav dropdown item
        </CNavItem>
        <CNavItem href="#">
          <CIcon  customClassName="nav-icon" icon="cil-puzzle"/> Nav dropdown item
        </CNavItem>
      </CNavGroup> -->
    </CSidebarNav>
    <CSidebarFooter>
      <CSidebarNav>
        <CNavItem href="#" class="text-light">
          <CNavLink
            href="javascript:void(0);"
            @click="$router.push({name: 'auth-login'})"
          >
            <div class="w-100">
              <strong>LOGOUT</strong>
            </div>
          </CNavLink>
        </CNavItem>
      </CSidebarNav>
    </CSidebarFooter>
    <!-- <CSidebarToggler/> -->
</CSidebar>
</template>

<script>
export default {
  name:"SidebarView"
}
</script>

<style>
.sidebar{
  background:#000 !important;
}
.sidebar-brand{
  background:rgb(54, 54, 54) !important;
}
</style>